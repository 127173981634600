<template>
  <HomeView/>
</template>

<script>
import HomeView from './components/HomeView.vue';
export default {
  name: 'App',
  components: {
    HomeView
  },
  created () {
    document.title = "ache";
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
body {
  background-color: black;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: 'Outfit', sans-serif;
  color: white;
}
* {
  transition-duration: 800ms;
}
</style>
