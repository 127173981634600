<template>
  <div class="home">
    <div class="desktop">
      <img src="@/assets/desktop.png" alt="img" class="bg">
      <div class="links">
        <div style="padding-bottom: 30vh;">
          <img src="@/assets/logo.png" alt="logo" class="logo">
        </div>
        <h3 style="color: red;">MY LINKS</h3>
        <div>
          <a href="https://lustinnoir.com" target="_blank">LUST IN NOIR</a>
        </div>
        <div>
          <a href="https://instagram.com/mattiaguercia" target="_blank">INSTAGRAM</a>
        </div>
        <div>
          <a href="https://tiktok.com/mattiaguercia" target="_blank">TIKTOK</a>
        </div>
        <div>
          <a href="https://open.spotify.com/playlist/2283FVWQAaHd4OX3TcE9uD?si=3c2d94e4c4c44301" target="_blank">SPOTIFY</a>
        </div>
      </div>
    </div>
    <div class="mobile">
      <img src="@/assets/mobile.png" alt="img" >
      <div class="wrapper">
        <div style="text-align: center;">
          <img src="@/assets/logo.png" alt="logo" class="logo">
        </div>
        <div class="links">
          <div>
            <a href="https://lustinnoir.com" target="_blank" style="margin-left: 11vw;">LUST IN NOIR</a>
          </div>
          <div style="text-align: right;">
            <a href="https://instagram.com/mattiaguercia" target="_blank">INSTAGRAM</a>
          </div>
          <div>
            <a href="https://tiktok.com/mattiaguercia" target="_blank">TIKTOK</a>
          </div>
          <div style="text-align: right; margin-right: 15vw;">
          <a href="https://open.spotify.com/playlist/2283FVWQAaHd4OX3TcE9uD?si=3c2d94e4c4c44301" target="_blank">SPOTIFY</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
}
</script>


<style scoped>
@media only screen and (max-width: 768px) {
  .mobile {
    display: block !important;
  }
  .desktop {
    display: none !important;
  }
}
.desktop > .bg {
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  z-index: -1;
}
.desktop > .links > div > .logo {
  width: 15vw;
}
.desktop > .links {
  padding-top: 10vh;
  margin-left: 5vw;
}
.desktop > .links > div >  a {
    font-size: 2vw;
    color: white;
    font-weight: 700;
    text-decoration: none;
  }
  .mobile {
    display: none;
  }
  .home {
    overflow: hidden;
  }
  .mobile > img {
    top: 15vh;
    width: 100vw;
    position: absolute;
    z-index: -1;
  }
  .mobile > .wrapper {
    padding: 3vh 8vw 2vh;
    font-size: 3vw;
    display: flex;
    flex-direction: column;
  }
  .mobile > .wrapper > * {
    margin: 0;
  }
  .mobile > .wrapper > h3 {
    color: red;
    margin-top: 1.5vh;
  }
  .mobile > .wrapper > div {
    width: 100%;
  }
  .mobile > .wrapper > .links {
    display: flex;
    flex-direction: column;
    gap: 10vh;
    padding-top: 17vh;
  }
  .mobile > .wrapper > .links > div >  a {
    color: white;
    font-size: 4vw;
    font-weight: 700;
    text-decoration: none;
  }
  .mobile > .wrapper > div > .logo {
    width: 38vw;
  }
  a:hover {
    color: red !important;
  }
</style>
